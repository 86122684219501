<template>
  <div style="padding:16px">

    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="18">
          <el-button type="primary" @click="newBtn">新建</el-button>
        </el-col>
        <el-col :span="6">
          <el-input placeholder="请输入内容" v-model="queryInfo.condition.queryKey" class="input-with-select" clearable>
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%" :height="height">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column label="分类名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.videoCategoryName }}</template>
          </el-table-column>
          <el-table-column label="短视频名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.videoName }}</template>
          </el-table-column>
          <el-table-column label="短视频标题" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.title }}</template>
          </el-table-column>
          <el-table-column label="短视频封面" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-image  style="width: 80px; height: 80px;"
              :preview-src-list="scope.row.showImage.split(',')" :src="scope.row.showImage">
            </el-image>
            </template>
          </el-table-column>
          <el-table-column label="短视频" align="center" show-overflow-tooltip width="180px">
            <template slot-scope="scope">
              <video class="videoPopop" controls :src="scope.row.fileUrl" style="width:160px;"></video>
            </template>
          </el-table-column>
          <!-- <el-table-column label="短视频url地址" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.fileUrl }}</template>
          </el-table-column> -->
          <!-- <el-table-column label="小图" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.showImage }}</template>
          </el-table-column> -->
          <!-- <el-table-column label="短视频分类编码" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.videoCategoryCode }}</template>
          </el-table-column>
          <el-table-column label="短视频分类名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.videoCategoryName }}</template>
          </el-table-column>
          <el-table-column label="关键词" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.keyword }}</template>
          </el-table-column>
          <el-table-column label="标题" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.title }}</template>
          </el-table-column>
          <el-table-column label="描述" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.description }}</template>
          </el-table-column> -->
          <el-table-column label="排序" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.seq }}</template>
          </el-table-column>
          <!-- <el-table-column label="创建人" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createBy }}</template>
          </el-table-column> -->
          <el-table-column label="创建时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createDate }}</template>
          </el-table-column>
        <el-table-column label="操作" width="135" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
            <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.id)">
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[10, 20, 30, 40]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="(labelType=='add'?'新增':'编辑')" :visible.sync="showDialog" width="800px"  destroy-on-close>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" :key="timer">
        <el-row :gutter="10">

            <!-- <el-col :span="12">
              <el-form-item label="：" prop="siteName">
                <el-input v-model="form.id" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="关联编码：" prop="siteName">
                <el-input v-model="form.videoCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="短视频名称：" prop="siteName">
                <el-input v-model="form.videoName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="视频分类名称：" prop="siteName">
                <el-select v-model="form.videoCategoryCode" placeholder="请选择" style="width: 100%;" @change="chooseName"  ref="category">
                  <el-option
                    v-for="item in options"
                    :key="item.videoCategoryCode"
                    :label="item.videoCategoryName"
                    :value="item.videoCategoryCode">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="短视频名称：" prop="siteName">
                <el-input v-model="form.videoName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="短视频标题：" prop="siteName">
                <el-input v-model="form.title" placeholder="请输入标题"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="排序：" prop="siteName">
                <el-input v-model="form.seq" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="短视频上传：" prop="fileUrl">
              <el-upload class="avatar-uploader" :action="$uploadURL" :show-file-list="false"
                :on-success="videoSuccess" :limit="1" :on-remove="removeVideo" :on-change="videohandleChange" ref="picUpload">
                <video v-if="form.fileUrl" class="avatarVideo" :src="form.fileUrl"></video>
                <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
        </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="封面图片：" prop="coverImage" label-width="120px">
              <el-upload class="avatar-uploader" :action="$uploadURL" :show-file-list="false"
                :on-success="videoImageSuccess">
                <img v-show="form.showImage" :src="form.showImage" class="avatar">
                <div v-show="!form.showImage">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
            </el-form-item>
            </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      labelType: 'add',
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {}
      },
      form: {
        videoCategoryName:'',
        videoCategoryCode:'',
        videoName:'',
        seq:'',
        fileUrl:'',
        title:'',
        showImage:'',
        isPortraitScreen:''
      },
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0,
      options:[],
      timer:''
    };
  },

  created () {
    this.getList()
  },

  mounted () {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener('resize', this.getHeight())
    })
  },

  methods: {
    // 监听表格高度
    getHeight () {
      this.height = window.innerHeight - this.headerHeight - 256;
    },

    //获取数据
    getList () {
	  var that = this;
	  that.$http.post("/videoManage/list", that.queryInfo).then(function (response) {
        // console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
          // that.$notify.success({
          //     title: "提示",
          //     message: "获取数据成功",
          //     showClose: true,
          //   });
        }else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
      });
    },

    // 保存
    handleSave () {
      console.log('111');
	    var that = this;
	  that.$http.post("/videoManage/save", that.form).then(function (response) {
		  if (response.data.code == 200) {
			  that.$notify.success({
				title: "提示",
				message: "保存成功",
				showClose: true,
			  });
			 that.showDialog = false
			  that.getList()
		  }else{
			  that.$notify.info({
				title: "提示",
				message: response.data.message,
				showClose: true,
			  });
		  }
      }).catch(function(error){


      })
	  
	  
    },

    //修改
    edit (row) {
      this.showDialog = true;
      this.labelType = 'edit'
      this.getVideocategory()
      this.form = JSON.parse(JSON.stringify(row))
    },

	
	// 删除用户
    async handleDelte(id) {
      console.log('111');
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
           var that = this;
        that.$http.post("/videoManage/delete" ,{'id':id}).then(function (response) {
			if(response.data.code == 200){
			 that.$notify.success({
				title: "提示",
				message: "删除成功",
				showClose: true,
			  });
			  that.getList();
			}else{
				 that.$notify.info({
					title: "提示",
					message: response.data.message,
					showClose: true,
				  });
			}
         
        });
      }
    },

    //新增按钮
    newBtn () {
      this.labelType = 'add'
      this.showDialog = true;
      this.form = {}
      this.getVideocategory()
    },

    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },

    // 获取短视频分类
    getVideocategory() {
      var that = this
      that.$http.post('/videoCategory/queryList',{})
      .then(function(response) {
        console.log(response);
        if(response.data.code == 200) {
          that.options = response.data.data
          that.options.forEach(item => {
            item.videoCategoryCode = item.categoryCode
            item.videoCategoryName = item.categoryName
            // console.log(that.options);
          })
          // that.$notify.success({
          //     title: "提示",
          //     message: "获取数据成功",
          //     showClose: true,
          //   });
        }else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        // console.log(that.options);
      })
    },

     // 视频上传成功
     videoSuccess(val) {
        console.log(val);
        this.form.fileUrl = val.data.url
        this.timer = new Date().getTime()
      },

      
    // 短视频上传改变
    videohandleChange(val) {
      var that = this
      console.log(val);
      this.$nextTick(() => {
        let url = val.response?.data?.url;
  const newvideoUrl = url;
  const videoObj = document.createElement('video')
  videoObj.preload = 'metadata'
  videoObj.src = newvideoUrl
  videoObj.onloadedmetadata = function (evt) {
    URL.revokeObjectURL(newvideoUrl)
    let width = parseInt(videoObj.videoWidth) //获取视频的宽
    let height = parseInt(videoObj.videoHeight)  //获取视频的高
    console.log('width, height:', width, height)
    if(width > height) {
      that.form.isPortraitScreen = '0'
  }else if(width < height){
    that.form.isPortraitScreen = '1'
  }
  // console.log(that.videoForm.isPortraitScreen);
  }
  
      })
      
    },

     // 短视频封面上传
     videoImageSuccess(val) {
      console.log(val);
      this.form.showImage = val.data.url;
      this.timer = new Date().getTime()
    },

      // 移除视频
      removeVideo(val) {
        console.log(val);
      },

      // 选择上级名称
    chooseName (val) {
      console.log('val',val);
      
      this.$nextTick(() => {
        console.log(this.$refs.category.selectedLabel);
        this.form.videoCategoryName = this.$refs.category.selectedLabel
      })
      // this.form.videoCategoryName = this.$refs.category
        // console.log(this.$refs.category)
    }

  },
};
</script>

<style scoped>
.videoPopop{
  width: 180px;
  height: 120px;
}

.avatar-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 180px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.avatarVideo {
  width: 180px;
  height: 150px;
  display: block;
}

.avatar {
  width: 180px;
  height: 150px;
  display: block;
}
</style>

